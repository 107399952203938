import * as React from "react"

import { Col, Card } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMicrochip,
  faMemory,
  faHdd,
  faRupeeSign,
} from "@fortawesome/free-solid-svg-icons"
import Image from "./image"

const Product = props => {
  return (
    <Col sm={9} md={7} lg={props.col} key={props.id}>
      <Card className="mb-5">
        <Image
          src={props.image}
          className="card-img-top fluid-img m-auto"
          alt="Sunset Image"
        />

        <Card.Body>
          <Card.Title>{props.name}</Card.Title>
          <Card.Text as="div">
            <div className="d-flex justify-content-start">
              <div className="col-1 mr-5">
                <FontAwesomeIcon icon={faMicrochip} />
              </div>
              <div className="col-5 mr-5">Processor</div>
              <div className="col-6 mr-5">{props.processor}</div>
            </div>
            <div className="d-flex justify-content-start">
              <div className="col-1 mr-5">
                <FontAwesomeIcon icon={faMemory} />
              </div>
              <div className="col-5 mr-5">RAM</div>
              <div className="col-6 mr-5">{props.memory}</div>
            </div>
            <div className="d-flex justify-content-start">
              <div className="col-1 mr-5">
                <FontAwesomeIcon icon={faHdd} />
              </div>
              <div className="col-5 mr-5">Storage</div>
              <div className="col-6 mr-5">{props.storage}</div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default Product
